import CameraSvg from '^/assets/icons/mypage/camera-icon.svg';
import UserSvg from '^/assets/icons/mypage/user.svg';
import { Tuple } from '^/components/molecules/MyPage/Tuple';
import { useL10n } from '^/hooks';
import { ChangeAuthedUser } from '^/store/duck/Auth';
import { usePopupStore } from '^/store/popup';
import { useAuthUserQuery } from '^/store/react-query/users';
import theme from '^/theme';
import * as T from '^/types';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { GroupedSection } from './GroupedSection';
import Text from './text';
const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingInline: '60px',
  paddingTop: '22px',
});

const AvatarWrapper = styled.div({
  position: 'relative',
  height: '100px',
  width: '100px',
  marginTop: '20px',
});

const AvatarContainer = styled.div({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#56CB8C',
  borderRadius: '50%',
});

const CameraButtonWrapper = styled.button({
  display: 'flex',
  position: 'absolute',
  bottom: '0',
  right: '0',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  ':hover': {
    filter: 'brightness(0.9)',
  },
});

const AvatarImage = styled.img({
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  objectFit: 'cover',
  objectPosition: 'center',
});

const MyPageAccount = () => {
  const [l10n, language] = useL10n();
  const { data: authedUser } = useAuthUserQuery();
  const dispatch = useDispatch();

  const setMinorPopup = usePopupStore(s => s.setMinorPopup);
  const setPopupOptions = usePopupStore(s => s.setPopupOptions);
  const fullName = useMemo(() => {
    if (!authedUser?.firstName && !authedUser?.lastName) {
      return 'N/A';
    }
    if (authedUser?.firstName && authedUser?.lastName) {
      return language === T.Language.EN_US
        ? `${authedUser.firstName} ${authedUser.lastName}`
        : `${authedUser.lastName} ${authedUser.firstName}`;
    }
    return authedUser?.firstName ? authedUser.firstName : authedUser?.lastName;
  }, [authedUser?.firstName, authedUser?.lastName, language]);

  const logout = () => {
    dispatch(ChangeAuthedUser({}));
  };

  const handleOpenPopup = (type: T.MyPagePopupContentTypes) => {
    setPopupOptions({ myPagePopupType: type });
    setMinorPopup(T.ContentPageMinorPopupType.MY_PAGE_POPUP);
  };

  const avatar = (
    <AvatarWrapper>
      <AvatarContainer>
        {authedUser?.avatar ? <AvatarImage src={authedUser.avatar} /> : <UserSvg />}
      </AvatarContainer>
      <CameraButtonWrapper
        onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_PROFILE_IMAGE)}
      >
        <CameraSvg />
      </CameraButtonWrapper>
    </AvatarWrapper>
  );

  const purposeDisplayText = useMemo(() => {
    if (authedUser?.purpose) {
      return l10n(Text.purposeOptions[authedUser?.purpose as keyof typeof Text.purposeOptions]);
    }
    return l10n(Text.purposeOptions.default);
  }, [authedUser?.purpose]);

  return (
    <Root data-testid="my-page-account">
      <GroupedSection
        title={l10n(Text.account.profile.title)}
        componentToShowWithTitle={avatar}
        testId="my-page-account-profile"
      >
        <Tuple
          title={l10n(Text.account.profile.email)}
          value={authedUser?.email}
          boldValue={true}
          nonEditable={true}
          testId="my-page-account-profile-email"
        />
        <Tuple
          title={l10n(Text.account.profile.name)}
          value={fullName}
          boldValue={true}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_NAME)}
          testId="my-page-account-profile-name"
        />
        <Tuple
          title={l10n(Text.account.profile.phoneNumber)}
          value={authedUser?.contactNumber}
          boldValue={true}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_PHONE)}
          testId="my-page-account-profile-phone"
        />
      </GroupedSection>
      <GroupedSection
        title={l10n(Text.account.affiliation.title)}
        topBorder={true}
        testId="my-page-account-affiliation"
      >
        <Tuple
          title={l10n(Text.account.affiliation.organization)}
          value={authedUser?.organization}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_ORGANIZATION)}
          testId="my-page-account-affiliation-organization"
        />
        <Tuple
          title={l10n(Text.account.affiliation.purpose)}
          value={purposeDisplayText}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_PURPOSE)}
          testId="my-page-account-affiliation-purpose"
        />
      </GroupedSection>
      <GroupedSection
        title={l10n(Text.account.session.title)}
        topBorder={true}
        testId="my-page-account-session"
      >
        <Tuple
          title={l10n(Text.account.session.signOut)}
          titleCustomStyles={{
            fontWeight: '700',
            color: theme.colorTokens.errorRed,
          }}
          onClick={logout}
          testId="my-page-account-session-sign-out"
        />
      </GroupedSection>
    </Root>
  );
};

export default MyPageAccount;
