import { Tuple } from '^/components/molecules/MyPage/Tuple';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { GroupedSection } from './GroupedSection';
import { useAuthUserQuery } from '^/store/react-query/users';
import { useL10n } from '^/hooks';
import Text from './text';
import * as T from '^/types';
import { usePopupStore } from '^/store/popup';
const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingInline: '60px',
  paddingTop: '22px',
});

const languageTypeToLanguageText: Record<T.Language, string> = {
  [T.Language.EN_US]: 'English',
  [T.Language.KO_KR]: '한국어',
};

export default function MyPageSettings() {
  const [l10n] = useL10n();
  const setPopup = usePopupStore(s => s.setMinorPopup);
  const setPopupType = usePopupStore(s => s.setPopupOptions);
  const { data: authedUser } = useAuthUserQuery();

  const language = useMemo(() => {
    if (!authedUser?.language) {
      return languageTypeToLanguageText[T.Language.EN_US];
    }
    return languageTypeToLanguageText[authedUser.language];
  }, [authedUser?.language]);

  const handleOpenPopup = (type: T.MyPagePopupContentTypes) => {
    setPopup(T.ContentPageMinorPopupType.MY_PAGE_POPUP);
    setPopupType({
      myPagePopupType: type,
    });
  };

  return (
    <Root data-testid="my-page-settings">
      <GroupedSection title={l10n(Text.settings.locale.title)} testId="my-page-settings-locale">
        <Tuple
          title={l10n(Text.settings.locale.country)}
          value={authedUser?.country}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_COUNTRY)}
          testId="my-page-settings-locale-country"
        />
        <Tuple
          title={l10n(Text.settings.locale.language)}
          value={language}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_LANGUAGE)}
          testId="my-page-settings-locale-language"
        />
      </GroupedSection>
    </Root>
  );
}
