import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  MouseEvent,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { EditableText, Props as EditableTextProps } from '../EditableText';
import route from '^/constants/routes';
import {
  UseEditableTextOutput,
  UseState,
  useEditableText,
  useIsRoleX,
  useLastSelectedScreen,
  useRouteIsMatching,
} from '^/hooks';
import { PatchContent } from '^/store/duck/Contents';
import { CategoryToTabMapper, ChangeSelectedGroupId } from '^/store/duck/Groups';
import * as T from '^/types';
import { isRoleViewer } from '^/utilities/role-permission-check';
import { ContentFilterContext } from '^/components/molecules/ContentsSearchBox/context';
import { useESSContents } from '^/hooks/useESSContents';
import { useESSContentsStore } from '^/store/essContentsStore';
import { groupStore, useGroupStore } from '^/store/zustand/groups/groupStore';
import { useUtilsStore } from '^/store/utilsStore';

export interface Props {
  readonly group: T.GroupableContent;
  readonly isEditing?: boolean;
  readonly initialText: string;
  readonly fromUI: T.EditableTextUI;
  readonly isSnapEnabled?: boolean;
}

/**
 * @function GroupedContentsTitle
 * @description Inner callbacks should not be memoized because it should capture `editingText` value on every render.
 */
export const GroupedContentsTitle: FC<Props> = memo(
  ({ group, isEditing = false, initialText, fromUI, isSnapEnabled }) => {
    const { updateESSContent } = useESSContents();
    const setSelectedESSGroupIdByTab = useESSContentsStore(
      state => state.setSelectedESSGroupIdByTab
    );
    const dispatch: Dispatch = useDispatch();
    const { filterText, selectedFilterContents } = useContext(ContentFilterContext);
    const projectId: T.Project['id'] | undefined = useSelector(
      (s: T.State) => s.ProjectConfigPerUser.config?.projectId
    );
    const isCreatingNewGroup: T.GroupsState['isCreatingNewGroup'] = useGroupStore(
      s => s.isCreatingNewGroup
    );
    const isOnSharePage: boolean = useRouteIsMatching(route.share.main);
    const isViewer: boolean = useIsRoleX(isRoleViewer);
    const lastSelectedScreen: T.Screen | undefined = useLastSelectedScreen();

    const setEditingTitleContentId = useUtilsStore(s => s.setEditingTitleContentId);

    const [isTitleEditing, setIsTitleEditing]: UseState<Readonly<boolean>> =
      useState<Readonly<boolean>>(isCreatingNewGroup);

    // When a group has just been created,
    // automatically select them so that users can immediately create contents to it.
    useEffect(() => {
      if (isCreatingNewGroup) {
        if (group.category === T.ContentCategory.ESS /* isESS */) {
          setSelectedESSGroupIdByTab(group.id);
        } else {
          dispatch(
            ChangeSelectedGroupId({
              selectedGroupId: group.id,
              tab: CategoryToTabMapper[group.category],
            })
          );
        }
      }
    }, []);

    useEffect(
      () => () => {
        const lastTitleBeforeUnmount: string | undefined = editingTextRef.current?.value;
        if (
          lastTitleBeforeUnmount !== undefined &&
          lastTitleBeforeUnmount !== group.title &&
          lastTitleBeforeUnmount.trim().length
        ) {
          if (lastSelectedScreen === undefined) {
            return;
          }

          updateGroupName();
        }
      },
      []
    );

    const handleTitleSave: () => void = () => {
      if (!editingText.trim().length) {
        setIsTitleEditing(false);
        groupStore.setState({ isCreatingNewGroup: false });
        setEditingTitleContentId(null);

        return;
      }

      if (group.title !== editingText) {
        updateGroupName();
      }

      setEditingTitleContentId(null);
      setIsTitleEditing(false);
      groupStore.setState({ isCreatingNewGroup: false });
    };

    const {
      editingTextRef,
      editingText,
      setEditingText,
      textRef,
      ...otherProps
    }: UseEditableTextOutput = useEditableText({
      handleTextSave: handleTitleSave,
      defaultText: group.title,
      isEditing: isTitleEditing,
      setIsEditing: setIsTitleEditing,
      contentId: group.id,
    });

    const updateGroupName: () => void = useCallback(() => {
      if (lastSelectedScreen === undefined || projectId === undefined) {
        return;
      }

      switch (group.category) {
        case T.ContentCategory.ESS: {
          void updateESSContent({
            content: { id: group.id, title: editingText },
            isUndoable: true,
            isTitle: true,
          });
          break;
        }
        default: {
          dispatch(
            PatchContent({ content: { id: group.id, title: editingText }, isUndoable: true })
          );
          break;
        }
      }
    }, [projectId, lastSelectedScreen?.id, group.id, editingText, group.category]);

    const handleTextDivClick: ((e: MouseEvent<HTMLDivElement>) => void) | undefined = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        if (isOnSharePage || isViewer) {
          return;
        }
        if (isEditing) {
          e.stopPropagation();
        }

        setEditingText(group.title);
        setIsTitleEditing(true);
      },
      [isOnSharePage, isViewer, isEditing, group.title]
    );

    const editableTextProps: EditableTextProps = useMemo(
      () => ({
        ...otherProps,
        editingTextRef,
        editingText,
        textRef,
        isTextEditable: !isOnSharePage,
        isTextEditing: isTitleEditing,
        fromUI,
        text: initialText,
        isGenericName: false,
        handleTextDivClick,
        showSnapBadge: isSnapEnabled,
        filterText:
          group.type === T.ContentType.GROUP
            ? selectedFilterContents.includes(T.ContentType.GROUP)
              ? filterText
              : ''
            : filterText,
      }),
      [
        otherProps,
        editingTextRef,
        editingText,
        textRef,
        isOnSharePage,
        isTitleEditing,
        fromUI,
        initialText,
        handleTextDivClick,
        filterText,
        selectedFilterContents,
        group,
        isSnapEnabled,
      ]
    );

    return <EditableText {...editableTextProps}>{initialText}</EditableText>;
  }
);
