/* eslint-disable max-lines */
import Tippy from '@tippyjs/react';
import React, {
  FC,
  KeyboardEvent,
  memo,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action, Dispatch } from 'redux';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import styled from 'styled-components';
import { MathUtils } from 'three';

import CenteredViewSvg from '^/assets/icons/centered-view.svg';
import TwoDBadgeSvg from '^/assets/icons/contents-list/2d-badge.svg';
import ThreeDBadgeSvg from '^/assets/icons/contents-list/3d-badge.svg';
import ToggleSvg from '^/assets/icons/contents-list/arrow.svg';
import DisabledToggleSvg from '^/assets/icons/contents-list/disabled-arrow.svg';
import DownloadGrayActivatedSvg from '^/assets/icons/download-gray-activated.svg';
import { ContentIcon as RawContentIcon } from '^/components/atoms/ContentIcon';
import { ContentsListItemCheckbox as Checkbox } from '^/components/atoms/ContentsListItemCheckbox';
import ContentColor from '^/components/atoms/ContentsListItemColor';
import { ContentsListItemTitle as Title } from '^/components/atoms/ContentsListItemTitle';
import CopyContentButton from '^/components/atoms/CopyContentButton';
import DeleteContentButton from '^/components/atoms/DeleteContentButton';
import { CesiumContext, CesiumContextProps } from '^/components/cesium/CesiumContext';
import { zoomToCoordinate } from '^/components/cesium/cesium-util';
import { downloadGCP } from '^/components/molecules/ContentsListGCPGroupItem/utils';
import { CANCELLABLE_CLASS_NAME } from '^/components/molecules/CreatingVolumeClickEventHandler';
import { usePotreeStore } from '^/components/potree/PotreeStore';
import { useThreeStore } from '^/components/three/ThreeStore';
import { CAMERA_ZOOM_MEASUREMENT_CENTER } from '^/components/three/constants';
import {
  findAverage,
  findPointBetween,
  geoPointToPotreeCloud,
  moveCameraToViewObject,
} from '^/components/three/utils';
import dsPalette from '^/constants/ds-palette';
import palette from '^/constants/palette';
import { DISABLED_CONTENT_OPACITY, MediaQuery } from '^/constants/styles';
import {
  isESSCustomModel,
  typeGuardESSContent,
  useDeleteContent,
  UseDeleteContent,
  UseL10n,
  useL10n,
  useProjectCoordinateSystem,
  UseShouldContentDisabled,
  useShouldContentDisabled,
  UseState,
} from '^/hooks';
import { useESSContents } from '^/hooks/useESSContents';
import {
  contentsSelector,
  DownloadOverlay,
  PatchContent,
  PatchGCPContent,
  RequestVolumeCalculation,
} from '^/store/duck/Contents';
import {
  ChangeEditingContent,
  ChangeIn3D,
  ChangeIn3DPointCloud,
  ChangeTwoDDisplayCenter,
} from '^/store/duck/Pages';
import { useESSContentsStore } from '^/store/essContentsStore';
import { useFlightPlanStore } from '^/store/flightPlanStore';
import { useFlightScheduleStore } from '^/store/flightScheduleStore';
import { useSnappingStore } from '^/store/snapping/snappingStore';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { useGroupStore } from '^/store/zustand/groups/groupStore';
import * as T from '^/types';
import { getCopiedContentTitle, getHasColor } from '^/utilities/annotation-content-util';
import {
  getCenterPointByContent,
  getCenterPointByContentInMesh,
  getContentTitlesByType,
} from '^/utilities/content-util';
import { getSingleContentId } from '^/utilities/state-util';
import ContentInfo from '../ContentInfo';
import Text from './text';
import SnapEnabledIndicator from '../SnapEnabledIndicator';
interface ItemProps {
  isEditing?: boolean;
  groupType?: 'root' | 'sub';
  isParentGroupSelected?: boolean;
}

interface DSMDTMButtonProps {
  isSelected: boolean;
  dsmOrDtmType: T.ContentType.DTM | T.ContentType.DSM;
  isDisabled?: boolean;
}

export const CONTENTSLIST_CTXSORT_KEY = 'ContentsListItem';

export const Root = styled.li({
  position: 'relative',
  listStyle: 'none',

  width: '100%',
  [MediaQuery[T.Device.MOBILE_L]]: {
    width: 'auto',
  },

  [MediaQuery[T.Device.MOBILE_S]]: {
    width: 'auto',
  },

  zIndex: 'auto',
});

export const SelectionBox = styled.div<ItemProps>(
  ({ isEditing, groupType, isParentGroupSelected }) => ({
    position: 'relative',

    display: 'flex',
    alignItems: 'center',

    boxSizing: 'border-box',
    height: '40px',
    paddingLeft: groupType ? (groupType === 'root' ? '44px' : '68px') : '24px',
    paddingRight: '12px',
    backgroundColor: isEditing
      ? palette.ContentsList.selected.toString()
      : isParentGroupSelected
      ? palette.ContentsList.childOfSelectedGroup.toString()
      : undefined,

    cursor: 'pointer',
    userSelect: 'none',

    [`&:hover ${ContentCenterButton}`]: {
      visibility: 'visible',
    },

    ' ::after': {
      content: "''",
      position: 'absolute',
      width: '36px',
      height: '100%',
      left: '-36px',
      top: '0',
      backgroundColor: isEditing ? palette.ContentsList.titleActiveLightBlue.toString() : undefined,
    },
  })
);
SelectionBox.displayName = 'SelectionBox';

const ToggleIconContainer = styled.div<Pick<ItemProps, 'isEditing'>>(({ isEditing }) => ({
  height: 20,
  width: 20,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  transform: isEditing ? 'rotate(-180deg)' : undefined,
  borderRadius: '3px',
  ':hover': {
    backgroundColor: '#CED7E5',
  },
}));

export const TitleWrapper = styled.div({
  position: 'relative',
  flex: 1,

  height: '32px',

  marginLeft: '4px',
});

const Balloon = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  padding: '25px',
  margin: 'auto',

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  background: palette.white.toString(),
  borderBottom: `1px solid ${palette.toggleButtonGray.toString()}`,
});

const BalloonHeader = styled.div<{ isMarginOnTitle: boolean }>(({ isMarginOnTitle }) => ({
  boxSizing: 'border-box',

  width: '100%',
  height: '100%',
  padding: isMarginOnTitle ? '0 15px' : undefined,

  display: 'flex',
  justifyContent: 'space-between',

  alignItems: 'center',
}));

const BalloonTitle = styled.div<DisabledProps>(({ isDisabled }) => ({
  display: 'flex',
  alignItems: 'center',

  fontSize: '13px',
  fontWeight: 'bold',
  color: dsPalette.title.toString(),

  span: {
    opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  },
}));

const BalloonCenterTools = styled.div<{ isMarginRequired: boolean }>(({ isMarginRequired }) => ({
  marginLeft: isMarginRequired ? 'auto' : '',
}));
const BalloonTools = styled.div<{ width: string }>(({ width }) => ({
  display: 'flex',
  position: 'relative',
  width,
  height: '13px',
  justifyContent: 'space-between',
  alignItems: 'center',

  marginLeft: '7px',

  'svg, img': {
    cursor: 'pointer',
  },
}));

export const HorizontalDivider = styled.div<DisabledProps>(({ isDisabled }) => ({
  opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
  width: '100%',
  borderTop: `1px solid ${palette.toggleButtonGray.toString()}`,

  marginTop: '13px',
  marginBottom: '13px',
}));

export const HorizontalWideDivider = styled(HorizontalDivider)({
  marginTop: '23px',
  marginBottom: '23px',
});

export const ContentIcon = styled<any>(RawContentIcon)<{ isProcessingOrFailed?: boolean }>(
  ({ isProcessingOrFailed }) => ({
    marginLeft: isProcessingOrFailed ? '12px' : '9px',
  })
);

export const FeatureWrapper = styled.div({
  marginLeft: 'auto',

  display: 'flex',
  alignItems: 'center',
});

const ContentButtonWrapper = styled.div<{ isProcessingOrFailed: boolean }>(
  ({ isProcessingOrFailed }) => ({
    marginTop: isProcessingOrFailed ? '-2px' : undefined,
  })
);

const DSMDTMButton = styled.button<DSMDTMButtonProps>(
  ({ dsmOrDtmType, isSelected, isDisabled = false }) => ({
    opacity: isDisabled ? DISABLED_CONTENT_OPACITY : 1,
    cursor: isDisabled ? 'default' : 'pointer',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '50px',
    height: '28px',
    marginRight: dsmOrDtmType === T.ContentType.DSM ? '0px' : '2px',
    marginLeft: dsmOrDtmType === T.ContentType.DTM ? '0px' : '2px',
    backgroundColor: isSelected
      ? palette.ContentsList.selectedButtonColor.toString()
      : palette.ContentsList.itemBackgroundGray.toString(),

    fontSize: '10px',
    fontWeight: 'bold',
    color: (isSelected ? palette.white : palette.ContentsList.title).toString(),

    borderRadius: (() => {
      switch (dsmOrDtmType) {
        case T.ContentType.DTM: {
          return '0px 8px 8px 0px';
        }
        case T.ContentType.DSM: {
          return '8px 0px 0px 8px';
        }
        default: {
          return undefined;
        }
      }
    })(),

    ':hover': !(isSelected || isDisabled)
      ? {
          backgroundColor: palette.ContentsList.hoverGray.toString(),
        }
      : undefined,
  })
);

const DSMDTMButtonWrapper = styled.div({
  position: 'relative',
  display: 'flex',
});

const BadgeWrapper = styled.div({
  paddingTop: '1px',
  marginRight: '3.5px',
});

const BadgeText = styled.div({
  fontSize: '9.75px',
  fontWeight: 'bold',
  color: 'white',
  backgroundColor: '#A2B2CA',
  padding: '3px 5px',
  borderRadius: '5px',
});

const ContentCenterButton = styled.div({
  height: 20,
  width: 20,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  marginRight: '8.5px',
  marginLeft: '3px',
  display: 'inline-flex',
  visibility: 'hidden',

  ':hover': {
    backgroundColor: palette.dropdown.dividerColor.toString(),
  },
});

function getDimensionBadge(type: T.ContentType, in3D: boolean): ReactNode {
  const in2D: boolean = !in3D;

  if (in3D && T.MAP_TAB_TWO_D_CONTENTS.includes(type)) {
    return (
      <BadgeWrapper>
        <TwoDBadgeSvg />
      </BadgeWrapper>
    );
  } else if (in2D && T.MAP_TAB_THREE_D_CONTENTS.includes(type)) {
    return (
      <BadgeWrapper>
        <ThreeDBadgeSvg />
      </BadgeWrapper>
    );
  }

  return undefined;
}

export interface Props {
  readonly content: T.Content;
  readonly firstBalloonTitle?: string;
  readonly firstBalloonDescription?: ReactNode | string;
  readonly className?: string;
  readonly isPinned?: boolean;
  displayCheckBox?: boolean;
  onClick?(): void;
}

interface DisabledProps {
  isDisabled?: boolean;
}
/**
 * If you pass children, it will be appended into first balloon
 * at the bottom of content title.
 */
export const ContentsListItemRaw: FC<PropsWithChildren<Props>> = ({
  content: { id: contentId },
  content,
  firstBalloonTitle,
  firstBalloonDescription,
  children,
  className,
  onClick,
  isPinned = false,
  displayCheckBox = true,
}) => {
  const { updateESSContent, duplicateESSContent } = useESSContents();
  const {
    editingESSContentId,
    essContents,
    essContentIds,
    selectedESSGroupIdByTab,
    setEditingESSContentId,
  } = useESSContentsStore(s => ({
    editingESSContentId: s.editingESSContentId,
    essContents: s.essContents,
    essContentIds: s.essContentIds,
    selectedESSGroupIdByTab: s.selectedESSGroupIdByTab,
    setEditingESSContentId: s.setEditingESSContentId,
  }));

  const { editingFlightPlanId, setEditingFlightPlanId, updateFlightPlans } = useFlightPlanStore(
    s => ({
      editingFlightPlanId: s.editingFlightPlanId,
      setEditingFlightPlanId: s.setEditingFlightPlanId,
      updateFlightPlans: s.updateFlightPlans,
    })
  );

  const { editingFlightScheduleId, setEditingFlightScheduleId, updateFlightSchedules } =
    useFlightScheduleStore(s => ({
      editingFlightScheduleId: s.editingFlightScheduleId,
      setEditingFlightScheduleId: s.setEditingFlightScheduleId,
      updateFlightSchedules: s.updateFlightSchedules,
    }));

  const dispatch: Dispatch = useDispatch();
  const { viewer }: CesiumContextProps = useContext(CesiumContext);

  const byId = useContentsStore(s => s.contents.byId);
  const selectedMainGroupIdByTab = useGroupStore(s => s.selectedGroupIdByTab);
  const viewer3Js = useThreeStore(s => s.viewer);
  const cameraControls = useThreeStore(s => s.cameraControls);
  const runtime = useThreeStore(s => s.runtime);
  const camera = useThreeStore(s => s.camera);

  const snapEnabledDxfIds = useSnappingStore(s => s.snapEnabledDxfIds);

  const PCO = usePotreeStore(s => s.PCO);
  const cameraP = usePotreeStore(s => s.camera);
  const sceneP = usePotreeStore(s => s.scene);

  const Pages = useSelector((s: T.State) => s.Pages);
  const ProjectConfigPerUser = useSelector((s: T.State) => s.ProjectConfigPerUser);
  const editingMainContentId = useSelector((s: T.State) => s.Pages.Contents.editingContentId);

  const dsmId: T.DSMContent['id'] | undefined = useMemo(
    () => getSingleContentId(Pages, ProjectConfigPerUser, T.ContentType.DSM),
    [Pages, ProjectConfigPerUser]
  );

  const dtmId: T.DSMContent['id'] | undefined = useMemo(
    () => getSingleContentId(Pages, ProjectConfigPerUser, T.ContentType.DTM),
    [Pages, ProjectConfigPerUser]
  );

  const dsmContent: T.DSMContent | undefined = byId[dsmId ?? NaN] as T.DSMContent | undefined;

  const dtmContent: T.DTMContent | undefined = byId[dtmId ?? NaN] as T.DTMContent | undefined;

  const isDSMMapSelected: boolean = contentsSelector.isSelected(ProjectConfigPerUser)(dsmId);
  const isDTMMapSelected: boolean = contentsSelector.isSelected(ProjectConfigPerUser)(dtmId);
  const shouldDTMContentBeShown: boolean = Boolean(
    content.type === T.ContentType.DSM && isDTMMapSelected
  );

  const [centerToIn3D, setCenterToIn3D] = useState<T.GeoPoint | null>(null);

  const [currentDSMDTMType, setCurrentDSMDTMType]: UseState<
    T.ContentType.DSM | T.ContentType.DTM | undefined
  > = useState(undefined);

  const isESS = content.category === T.ContentCategory.ESS;
  const isFlightPlan = content.type === T.ContentType.FLIGHT_PLAN;
  const isFlightSchedule = content.type === T.ContentType.FLIGHT_SCHEDULE;

  const isIn3D: T.ContentsPageState['in3D'] = Pages.Contents.in3D;
  const isIn3DPointCloud: T.ContentsPageState['in3DPointCloud'] = Pages.Contents.in3DPointCloud;
  const currentPointCloudEngine: T.ContentsPageState['currentPointCloudEngine'] =
    Pages.Contents.currentPointCloudEngine;
  const currentMeshEngine: T.ContentsPageState['currentMeshEngine'] =
    Pages.Contents.currentMeshEngine;

  const editingContentId = useMemo(() => {
    if (isESS) {
      return editingESSContentId;
    } else if (isFlightPlan) {
      return editingFlightPlanId;
    } else if (isFlightSchedule) {
      return editingFlightScheduleId;
    } else {
      return editingMainContentId;
    }
  }, [
    isESS,
    isFlightPlan,
    isFlightSchedule,
    editingESSContentId,
    editingFlightPlanId,
    editingFlightScheduleId,
    editingMainContentId,
  ]);

  const printingContentId: T.ContentsPageState['printingContentId'] =
    Pages.Contents.printingContentId;
  const isOverlayContent: boolean = T.OverlayContentTypes.includes(byId[content.id]?.type);
  const sidebarTab: T.ContentsPageState['sidebarTab'] = Pages.Contents.sidebarTab;

  const group = isESS ? essContents[content.groupId ?? NaN] : byId[content.groupId ?? NaN];
  const selectedGroupIdByTab = isESS ? selectedESSGroupIdByTab : selectedMainGroupIdByTab;
  const in3DPointCloud = Pages.Contents.in3DPointCloud;
  const isInDashboard = Pages.Contents.sidebarTab === T.ContentPageTabType.DASHBOARD;

  const isParentGroupSelected =
    Boolean(selectedGroupIdByTab[sidebarTab]) &&
    (group?.id === selectedGroupIdByTab[sidebarTab] ||
      group?.groupId === selectedGroupIdByTab[sidebarTab]);

  const [l10n]: UseL10n = useL10n();
  const rootRef: MutableRefObject<HTMLLIElement | null> = useRef(null);
  const projectProjection: T.ProjectionEnum = useProjectCoordinateSystem();
  const shouldContentDisabled: UseShouldContentDisabled = useShouldContentDisabled(
    content.type,
    content.config?.temporaryContentId
  );
  const ESSContent: T.ESSContent | undefined = typeGuardESSContent(content);

  const isEditing: boolean = useMemo(() => {
    if (content.type === T.ContentType.DSM) {
      return (isDTMMapSelected && editingContentId === dtmId) || editingContentId === content.id;
    } else {
      return content.id === editingContentId;
    }
  }, [content.id, editingContentId]);

  const threeDMeshId: T.MapContent['id'] | undefined = getSingleContentId(
    Pages,
    ProjectConfigPerUser,
    T.ContentType.THREE_D_MESH
  );

  // const another3DContentId: T.PointCloudContent['id'] | T.ThreeDOrthoContent['id'] | undefined =
  //   getSingleContentId(Contents, Pages, ProjectConfigPerUser, T.ContentType.THREE_D_ORTHO);

  const isProcessingOrFailed: boolean = contentsSelector.isProcessingOrFailedByContent(content);
  const isProcessing: boolean = contentsSelector.isProcessing(content);
  const isFailed: boolean = contentsSelector.isFailed(content);
  const isInCesium =
    isIn3D || (isIn3DPointCloud && currentPointCloudEngine === T.PointCloudEngine.CESIUM);
  const isInPotree = isIn3DPointCloud && currentPointCloudEngine === T.PointCloudEngine.POTREE;
  const isIn3DMesh = isIn3D && currentMeshEngine === T.MeshEngine.THREEJS;
  const isDTMMode: boolean =
    content.type === T.ContentType.VOLUME &&
    content.info.calculatedVolume.calculation.dsmorDtmMode === T.ContentType.DTM;

  // const isColorAtEnd: boolean = content.type === T.ContentType.MARKER;
  const isMarginOnTitle: boolean =
    content.type === T.ContentType.LENGTH ||
    content.type === T.ContentType.AREA ||
    content.type === T.ContentType.THREE_AREA ||
    content.type === T.ContentType.ESS_ARROW ||
    content.type === T.ContentType.ESS_POLYGON ||
    content.type === T.ContentType.ESS_POLYLINE ||
    content.type === T.ContentType.FLATTEN_MAP ||
    content.type === T.ContentType.MARKER;
  const isContentDSMorDTM: boolean = Boolean(
    dtmId && (content.type === T.ContentType.DSM || content.type === T.ContentType.DTM)
  );
  const isDTMContentProcessingCompleted: boolean =
    dtmContent?.status === T.ContentProcessingStatus.COMPLETED;

  // const handleMouseOver: () => void = useCallback(() => {
  //   setIsHovered(true);
  // }, [isHovered]);

  // const handleMouseLeave: () => void = useCallback(() => {
  //   setIsHovered(false);
  // }, [isHovered]);

  const color: string | undefined = getHasColor(content.type)
    ? content.color.toString()
    : undefined;

  const handleScrollIntoView: () => void = async () => {
    if (!rootRef?.current) {
      return;
    }
    scrollIntoViewIfNeeded(rootRef.current as Element, {
      scrollMode: 'if-needed',
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  };

  useEffect(() => {
    // if (isMobile()) {
    //   setIsHovered(isEditing);
    // }
    // // Use window.setTimeout for smoother animation
    if (isEditing) {
      window.setTimeout(handleScrollIntoView);
    }
  }, [isEditing]);

  useEffect(() => {
    if (viewer && centerToIn3D) {
      // todo: keep center position in redux and center cesium after it loads
      setTimeout(() => {
        zoomToCoordinate(viewer, centerToIn3D);
        setCenterToIn3D(null);
      }, 1500);
    }
  }, [viewer, centerToIn3D]);

  useEffect(() => {
    if (content.type === T.ContentType.VOLUME) {
      setCurrentDSMDTMType(
        content.info.calculatedVolume.calculation.dsmorDtmMode ?? T.ContentType.DSM
      );
    }
  }, []);

  const handleDownload: () => void = useCallback(() => {
    if (content.type === T.ContentType.GCP_GROUP) {
      if (content.info) {
        downloadGCP(content.info);
      }
    } else {
      dispatch(DownloadOverlay({ contentId: content.id }));
    }
  }, [content.id]);

  function unSelectMapItem(another3DContentType: T.Content['type']): void {
    const another3DContentId: T.PointCloudContent['id'] | T.ThreeDOrthoContent['id'] | undefined =
      getSingleContentId(Pages, ProjectConfigPerUser, another3DContentType);
    if (another3DContentId === undefined) {
      return;
    }

    const isAnother3DContentSelected: boolean =
      contentsSelector.isSelected(ProjectConfigPerUser)(another3DContentId);
    if (isAnother3DContentSelected) {
      dispatch(
        PatchContent({
          content: {
            id: another3DContentId,
            config: {
              selectedAt: undefined,
            },
          },
        })
      );
    }
  }

  function selectMapItem(selectContentType: T.ContentType): void {
    const selectContentId: T.PointCloudContent['id'] | T.ThreeDOrthoContent['id'] | undefined =
      getSingleContentId(Pages, ProjectConfigPerUser, selectContentType);

    if (!selectContentId) {
      return;
    }

    dispatch(
      PatchContent({
        content: {
          id: selectContentId,
          config: {
            selectedAt: new Date(),
          },
        },
      })
    );

    if (selectContentType === T.ContentType.DSM && dtmId) {
      if (isDTMMapSelected) {
        dispatch(
          PatchContent({
            content: {
              id: dtmId,
              config: {
                selectedAt: undefined,
              },
            },
          })
        );
      }
    }
    if (selectContentType === T.ContentType.DTM && dsmId) {
      if (isDSMMapSelected) {
        dispatch(
          PatchContent({
            content: {
              id: dsmId,
              config: {
                selectedAt: undefined,
              },
            },
          })
        );
      }
    }
  }

  function selectUnselectContents({
    typesToSelect,
    typesToUnselect,
  }: {
    typesToSelect: T.ContentType[];
    typesToUnselect: T.ContentType[];
  }) {
    typesToSelect.forEach(selectMapItem);
    typesToUnselect.forEach(unSelectMapItem);
  }

  const handleSwitchCaseContentChange = (
    contentType: T.ContentType,
    isGoingToBeEditing: boolean
  ) => {
    switch (contentType) {
      case T.ContentType.MAP:
        {
          selectUnselectContents({
            typesToSelect: [],
            typesToUnselect: [
              T.ContentType.THREE_D_MESH,
              T.ContentType.POINTCLOUD,
              T.ContentType.THREE_D_MESH,
            ],
          });

          if (in3DPointCloud) {
            dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
          }
          if (isIn3D) {
            dispatch(ChangeIn3D({ in3D: false, transition: true }));
          }
          dispatch(
            ChangeEditingContent({
              contentId: !isGoingToBeEditing ? undefined : contentId,
              skipSelecting: isProcessingOrFailed,
            })
          );
        }
        break;

      case T.ContentType.DSM:
        {
          selectUnselectContents({
            typesToSelect: [T.ContentType.MAP],
            typesToUnselect: [
              T.ContentType.THREE_D_MESH,
              T.ContentType.POINTCLOUD,
              T.ContentType.THREE_D_ORTHO,
            ],
          });

          if (isIn3D) {
            dispatch(ChangeIn3D({ in3D: false }));
          }
          dispatch(
            ChangeEditingContent({
              contentId: !isGoingToBeEditing ? undefined : isDTMMapSelected ? dtmId : contentId,
              skipSelecting: isProcessingOrFailed,
            })
          );
        }
        break;

      case T.ContentType.THREE_D_MESH:
        {
          selectUnselectContents({
            typesToSelect: [],
            typesToUnselect: [
              T.ContentType.THREE_D_ORTHO,
              T.ContentType.POINTCLOUD,
              T.ContentType.MAP,
              T.ContentType.DSM,
              T.ContentType.DTM,
            ],
          });

          if (in3DPointCloud) {
            dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
          }
          if (!isIn3D) {
            dispatch(ChangeIn3D({ in3D: true }));
          }

          dispatch(
            ChangeEditingContent({
              contentId: !isGoingToBeEditing ? undefined : contentId,
              skipSelecting: isProcessingOrFailed,
            })
          );
        }

        break;

      case T.ContentType.THREE_D_ORTHO:
        {
          selectUnselectContents({
            typesToSelect: [],
            typesToUnselect: [
              T.ContentType.THREE_D_MESH,
              T.ContentType.POINTCLOUD,
              T.ContentType.MAP,
              T.ContentType.DSM,
              T.ContentType.DTM,
            ],
          });

          if (in3DPointCloud) {
            dispatch(ChangeIn3DPointCloud({ in3DPointCloud: false }));
          }
          if (!isIn3D) {
            dispatch(ChangeIn3D({ in3D: true }));
          }

          dispatch(
            ChangeEditingContent({
              contentId: !isGoingToBeEditing ? undefined : contentId,
              skipSelecting: isProcessingOrFailed,
            })
          );
        }
        break;

      case T.ContentType.POINTCLOUD:
        selectUnselectContents({
          typesToSelect: [],
          typesToUnselect: [
            T.ContentType.THREE_D_ORTHO,
            T.ContentType.THREE_D_MESH,
            T.ContentType.MAP,
            T.ContentType.DSM,
            T.ContentType.DTM,
          ],
        });
        if (!in3DPointCloud) {
          dispatch(ChangeIn3DPointCloud({ in3DPointCloud: true }));
        }
        if (!isIn3D) {
          dispatch(ChangeIn3D({ in3D: true }));
        }
        dispatch(
          ChangeEditingContent({
            contentId: !isGoingToBeEditing ? undefined : contentId,
            skipSelecting: isProcessingOrFailed,
          })
        );
        break;

      default:
        {
          dispatch(
            ChangeEditingContent({
              contentId: !isGoingToBeEditing ? undefined : contentId,
              skipSelecting: isProcessingOrFailed,
              skipSwitchTab: isInDashboard,
            })
          );
        }
        break;
    }
  };

  const handleToggle: () => void = useCallback(() => {
    if (shouldContentDisabled) {
      return;
    }

    const isGoingToBeEditing: boolean = !isEditing;
    switch (sidebarTab) {
      case T.ContentPageTabType.ESS:
        setEditingESSContentId(isGoingToBeEditing ? contentId : undefined);
        break;
      case T.ContentPageTabType.FLIGHT_PLAN:
        if (isGoingToBeEditing) {
          updateFlightPlans({
            ...content,
            config: {
              type: T.ContentType.FLIGHT_PLAN,
              selectedAt: !isGoingToBeEditing ? undefined : new Date(),
            },
          } as T.FlightPlanContent);
        }
        setEditingFlightPlanId(isGoingToBeEditing ? contentId : undefined);
        break;
      case T.ContentPageTabType.FLIGHT_SCHEDULE:
        updateFlightSchedules({
          ...content,
          id: contentId,
          config: {
            type: T.ContentType.FLIGHT_SCHEDULE,
            selectedAt: !isGoingToBeEditing ? undefined : new Date(),
          },
        } as T.FlightScheduleContent);
        setEditingFlightScheduleId(isGoingToBeEditing ? contentId : undefined);
        break;
      default:
        handleSwitchCaseContentChange(content.type, isGoingToBeEditing);
    }
  }, [isProcessingOrFailed, shouldContentDisabled, isEditing, content, isIn3D]);

  const handleContentCenterClick = async () => {
    const actions: Action[] = [];

    if (content.type === T.ContentType.SLIDE && camera && runtime && cameraControls) {
      const [lat, long, height] = content.info.cameraPosition;
      const [x, y, z] = content.info.cameraRotation ?? [0, 0, 0];

      const point = runtime.getPositionFromLatLongHeight({
        lat,
        long,
        height,
      });

      void cameraControls?.moveTo(point.x, point.y, point.z, true);
      void cameraControls?.rotateTo(x, y, true);
      void cameraControls?.setFocalOffset(0, 0, 0);
      void cameraControls?.dollyTo(z, true);
    }

    if (!content.config?.selectedAt && content.type !== T.ContentType.GCP_GROUP) {
      switch (content.category) {
        case T.ContentCategory.FLIGHT_PLAN: {
          updateFlightPlans({
            ...content,
            config: {
              type: T.ContentType.FLIGHT_PLAN,
              selectedAt: new Date(),
            },
          } as T.FlightPlanContent);
          break;
        }
        case T.ContentCategory.ESS: {
          void updateESSContent({
            content: {
              id: contentId,
              config: {
                selectedAt: new Date(),
              },
            },
            skipDBUpdate: true,
          });
          break;
        }
        default:
          actions.push(
            PatchContent({
              content: {
                id: contentId,
                config: {
                  selectedAt: new Date(),
                },
              },
            })
          );
      }
    }

    const centerPoint: T.GeoPoint | undefined = getCenterPointByContent(
      content,
      dispatch,
      projectProjection
    );

    if (content.type === T.ContentType.GCP_GROUP) {
      actions.push(
        PatchGCPContent({
          content: {
            ...content,
            config: {
              ...content.config,
              selectedAt: new Date(),
            },
          },
        })
      );
    }

    if (content.type === T.ContentType.BIM) {
      if (threeDMeshId) {
        actions.push(
          ChangeIn3D({ in3D: true }),
          ChangeIn3DPointCloud({ in3DPointCloud: false }),
          PatchContent({
            content: {
              id: threeDMeshId,
              config: {
                selectedAt: new Date(),
              },
            },
          })
        );
      }

      await Promise.all(actions.map(dispatch)).then(() => moveCameraToViewObject(contentId));
      return;
    }

    if (centerPoint !== undefined) {
      if (isInCesium && viewer !== undefined) {
        if (isESSCustomModel(content)) {
          zoomToCoordinate(viewer, centerPoint, content.info.miscMeta.dimensions[0]);
        } else {
          zoomToCoordinate(viewer, centerPoint);
        }
      } else if (isInPotree) {
        if (cameraP && PCO && T.PointCloudMeasurementContentTypes.includes(content.type)) {
          const points = (content as T.ThreeDLengthContent).info.locations.map(
            location => geoPointToPotreeCloud(location, projectProjection, PCO).point
          );
          const measure = sceneP.measurements.find((v: any) => v.contentId === content.id);

          const center = findAverage(points);
          const newCameraPos = findPointBetween(
            center,
            cameraP.position,
            CAMERA_ZOOM_MEASUREMENT_CENTER
          );
          if (!newCameraPos) {
            return;
          }
          sceneP.view.lookAt(center);
          sceneP.view.setView(newCameraPos, measure);
        }
      } else {
        actions.push(ChangeTwoDDisplayCenter({ twoDDisplayCenter: centerPoint }));
      }
    }

    if (isIn3DMesh && runtime && viewer3Js) {
      const center = getCenterPointByContentInMesh(content, viewer3Js, runtime, projectProjection);
      if (
        (content.type === T.ContentType.BLUEPRINT_DXF ||
          content.type === T.ContentType.BLUEPRINT_DWG ||
          content.type === T.ContentType.ESS_MODEL) &&
        center
      ) {
        await cameraControls?.moveTo(center.x, center.y, center.z);
        await cameraControls?.rotateTo(0, 0);
        await cameraControls?.setFocalOffset(0, 0, 0);
        if (content.type === T.ContentType.ESS_MODEL) {
          await cameraControls?.dollyTo(20, true);
        } else {
          await cameraControls?.dollyTo(500, true);
        }
        await cameraControls?.rotate(45 * MathUtils.DEG2RAD, 45 * MathUtils.DEG2RAD, true);
      } else {
        moveCameraToViewObject(contentId);
      }
    }

    actions.forEach(dispatch);
  };

  const handleTopDSMButtonClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = event => {
    event.stopPropagation();
    if (dsmContent?.status === T.ContentProcessingStatus.PROCESSING) {
      return;
    }
    selectUnselectContents({
      typesToSelect: [T.ContentType.DSM],
      typesToUnselect: [
        T.ContentType.THREE_D_MESH,
        T.ContentType.POINTCLOUD,
        T.ContentType.THREE_D_ORTHO,
      ],
    });
    if (isIn3D) {
      dispatch(ChangeIn3D({ in3D: false }));
    }
  };
  const handleTopDTMButtonClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = event => {
    event.stopPropagation();
    if (dtmContent?.status === T.ContentProcessingStatus.PROCESSING) {
      return;
    }
    selectUnselectContents({
      typesToSelect: [T.ContentType.DTM],
      typesToUnselect: [
        T.ContentType.THREE_D_MESH,
        T.ContentType.POINTCLOUD,
        T.ContentType.THREE_D_ORTHO,
      ],
    });
    if (isIn3D) {
      dispatch(ChangeIn3D({ in3D: false }));
    }
  };

  const handleDSMButtonClick: () => void = () => {
    setCurrentDSMDTMType(T.ContentType.DSM);

    if (
      content.type !== T.ContentType.VOLUME ||
      content.info.calculatedVolume.calculation.dsmorDtmMode === T.ContentType.DSM
    ) {
      return;
    }

    switch (content.info.calculatedVolume.calculation.type) {
      case T.VolumeCalcMethod.BASIC:
        dispatch(
          RequestVolumeCalculation({
            contentId: content.id,
            info: {
              type: T.VolumeCalcMethod.BASIC,
              volumeAlgorithm:
                content.info.calculatedVolume.calculation.volumeAlgorithm ||
                T.BasicCalcBasePlane.TRIANGULATED,
              volumeElevation: 0,
              dsmorDtmMode: T.ContentType.DSM,
            },
            isDTMMode: false,
          })
        );
        break;
      case T.VolumeCalcMethod.DESIGN:
        {
          const isDesignDxfSelected = content.info.calculatedVolume.calculation.designDxfId;
          dispatch(
            RequestVolumeCalculation({
              contentId: content.id,
              info: {
                type: T.VolumeCalcMethod.DESIGN,
                designDxfId: isDesignDxfSelected,
                volumeAlgorithm: T.BasicCalcBasePlane.CUSTOM,
                volumeElevation: 0,
                dsmorDtmMode: T.ContentType.DSM,
              },
              isDTMMode: false,
            })
          );
        }
        break;
      default:
        return;
    }
  };

  const handleDTMButtonClick: () => void = () => {
    setCurrentDSMDTMType(T.ContentType.DTM);

    if (
      content.type !== T.ContentType.VOLUME ||
      content.info.calculatedVolume.calculation.dsmorDtmMode === T.ContentType.DTM
    ) {
      return;
    }

    switch (content.info.calculatedVolume.calculation.type) {
      case T.VolumeCalcMethod.BASIC:
        dispatch(
          RequestVolumeCalculation({
            contentId: content.id,
            info: {
              type: T.VolumeCalcMethod.BASIC,
              volumeAlgorithm:
                content.info.calculatedVolume.calculation.volumeAlgorithm ||
                T.BasicCalcBasePlane.TRIANGULATED,
              volumeElevation: 0,
              dsmorDtmMode: T.ContentType.DTM,
            },
            isDTMMode: true,
          })
        );
        break;
      case T.VolumeCalcMethod.DESIGN:
        {
          const isDesignDxfSelected = content.info.calculatedVolume.calculation.designDxfId;
          dispatch(
            RequestVolumeCalculation({
              contentId: content.id,
              info: {
                type: T.VolumeCalcMethod.DESIGN,
                designDxfId: isDesignDxfSelected,
                volumeAlgorithm: T.BasicCalcBasePlane.CUSTOM,
                volumeElevation: 0,
                dsmorDtmMode: T.ContentType.DTM,
              },
              isDTMMode: true,
            })
          );
        }
        break;
      default:
        return;
    }
  };

  const isMapRelatedContent: boolean = T.MAP_TAB_CONTENTS.includes(content.type);
  const dimensionBadge: ReactNode = isMapRelatedContent
    ? getDimensionBadge(content.type, isIn3D)
    : undefined;

  const dsmDtmBadge: ReactNode =
    content.type === T.ContentType.DSM && (isDTMMapSelected || isDSMMapSelected) ? (
      <BadgeWrapper>
        <BadgeText>{isDTMMapSelected ? l10n(Text.DTM) : l10n(Text.DSM)}</BadgeText>
      </BadgeWrapper>
    ) : undefined;

  const hasNoDownloadAndDelete: boolean = useMemo(
    () =>
      content.type === T.ContentType.THREE_D_ORTHO ||
      content.type === T.ContentType.GCP_GROUP ||
      content.type === T.ContentType.FLIGHT_SCHEDULE ||
      content.type === T.ContentType.DRONE_STATION,
    [content.type]
  );

  const hasDownload: boolean = useMemo(
    () => content.type === T.ContentType.GCP_GROUP,
    [content.type]
  );

  const shouldShowDSMDTMSelectbutton = content.type === T.ContentType.VOLUME && dtmId;

  const dsmdtmSelectButton: ReactNode = shouldShowDSMDTMSelectbutton ? (
    <DSMDTMButtonWrapper>
      <DSMDTMButton
        onClick={handleDSMButtonClick}
        isSelected={currentDSMDTMType === T.ContentType.DSM}
        dsmOrDtmType={T.ContentType.DSM}
        data-testid="dsm-button"
        data-selected={currentDSMDTMType === T.ContentType.DSM}
        data-content-type={T.ContentType.DSM}
      >
        {l10n(Text.DSM)}
      </DSMDTMButton>
      <DSMDTMButton
        onClick={handleDTMButtonClick}
        isSelected={currentDSMDTMType === T.ContentType.DTM}
        dsmOrDtmType={T.ContentType.DTM}
        data-content-type={T.ContentType.DTM}
        data-testid="dtm-button"
        data-selected={currentDSMDTMType === T.ContentType.DTM}
      >
        {l10n(Text.DTM)}
      </DSMDTMButton>
    </DSMDTMButtonWrapper>
  ) : undefined;

  const dsmdtmTopSelectButton: ReactNode =
    isContentDSMorDTM && isDTMContentProcessingCompleted ? (
      <DSMDTMButtonWrapper>
        <DSMDTMButton
          onClick={handleTopDSMButtonClick}
          isSelected={isDSMMapSelected}
          dsmOrDtmType={T.ContentType.DSM}
          isDisabled={dsmContent?.status === T.ContentProcessingStatus.PROCESSING}
          data-testid="dsm-button"
          data-selected={isDSMMapSelected}
          data-content-type={T.ContentType.DSM}
        >
          {l10n(Text.DSM)}
        </DSMDTMButton>
        <DSMDTMButton
          onClick={handleTopDTMButtonClick}
          isSelected={isDTMMapSelected}
          dsmOrDtmType={T.ContentType.DTM}
          isDisabled={dsmContent?.status === T.ContentProcessingStatus.PROCESSING}
          data-testid="dtm-button"
          data-selected={isDTMMapSelected}
          data-content-type={T.ContentType.DTM}
        >
          {l10n(Text.DTM)}
        </DSMDTMButton>
      </DSMDTMButtonWrapper>
    ) : undefined;

  const downloadButton: ReactNode = useMemo(
    () =>
      (!hasNoDownloadAndDelete && isOverlayContent) || hasDownload ? (
        <Tippy
          offset={T.TIPPY_OFFSET}
          theme="angelsw"
          placement="bottom"
          arrow={false}
          content={l10n(Text.tooltipDownload)}
        >
          <ContentButtonWrapper
            onClick={handleDownload}
            className={CANCELLABLE_CLASS_NAME}
            isProcessingOrFailed={isProcessingOrFailed}
            data-ddm-track-action={T.TrackActions.MAP_DOWNLOAD}
            data-ddm-track-label={`${T.TrackLabels.BTN_CONTENTS_LIST}-download-${content.type}`}
            data-testid={`download-button-${content.type}`}
          >
            <DownloadGrayActivatedSvg />
          </ContentButtonWrapper>
        </Tippy>
      ) : undefined,
    [
      hasNoDownloadAndDelete,
      hasDownload,
      isOverlayContent,
      handleDownload,
      isProcessingOrFailed,
      content.type,
    ]
  );

  const copySelectedESSContents = () => {
    if (ESSContent === undefined) {
      return;
    }
    if (ESSContent.id !== editingContentId) {
      return;
    }
    const allContents = essContentIds.includes(ESSContent.id) ? essContents : byId;
    const allContentTitlesWithSameType = getContentTitlesByType(allContents, ESSContent.type);
    const copiedContent = {
      ...ESSContent,
      title: getCopiedContentTitle(ESSContent.title, allContentTitlesWithSameType),
    };

    void duplicateESSContent({ content: copiedContent });
  };

  const copyButton: ReactNode = useMemo(() => {
    if (hasNoDownloadAndDelete || hasDownload || ESSContent === undefined) {
      return null;
    }

    return (
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="bottom"
        arrow={false}
        content={l10n(Text.tooltipCopy)}
      >
        <ContentButtonWrapper isProcessingOrFailed={isProcessingOrFailed}>
          <CopyContentButton
            content={content}
            isDisabled={Boolean(printingContentId) || isInDashboard}
          />
        </ContentButtonWrapper>
      </Tippy>
    );
  }, [
    content,
    hasNoDownloadAndDelete,
    hasDownload,
    printingContentId,
    isInDashboard,
    isProcessingOrFailed,
    ESSContent,
  ]);

  const deleteButton: ReactNode = useMemo(() => {
    if (hasNoDownloadAndDelete || hasDownload) {
      return null;
    }

    return (
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="bottom"
        arrow={false}
        content={l10n(Text.tooltipDelete)}
      >
        <ContentButtonWrapper
          className={CANCELLABLE_CLASS_NAME}
          isProcessingOrFailed={isProcessingOrFailed}
        >
          <DeleteContentButton
            content={content}
            isDisabled={Boolean(printingContentId) || isInDashboard}
          />
        </ContentButtonWrapper>
      </Tippy>
    );
  }, [
    content,
    hasNoDownloadAndDelete,
    hasDownload,
    printingContentId,
    isInDashboard,
    hasDownload,
    isProcessingOrFailed,
  ]);

  const balloonColor: ReactNode = useMemo(() => {
    if (!color) {
      return null;
    }

    return <ContentColor content={content} />;
  }, [content, color]);

  const contentCenterButton: ReactNode = useMemo(() => {
    if (
      (content.type !== T.ContentType.GCP_GROUP && isMapRelatedContent) ||
      isProcessingOrFailed ||
      isInDashboard
      // ||
      // content.type === T.ContentType.DESIGN_DXF
    ) {
      return null;
    }

    if (content.type === T.ContentType.GCP_GROUP && (isIn3D || isIn3DPointCloud)) {
      return null;
    }

    if (
      content.type === T.ContentType.FLIGHT_SCHEDULE ||
      content.type === T.ContentType.DRONE_STATION
    ) {
      return null;
    }

    return (
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="top"
        arrow={false}
        content={l10n(Text.tooltipCenter)}
      >
        <ContentCenterButton
          data-testid={`content-center-button-${content.type}`}
          onClick={handleContentCenterClick}
        >
          <CenteredViewSvg />
        </ContentCenterButton>
      </Tippy>
    );
  }, [
    isIn3D,
    isIn3DPointCloud,
    isInDashboard,
    // isHovered,
    isMapRelatedContent,
    isProcessingOrFailed,
    content,
    viewer3Js,
    runtime,
    isIn3DMesh,
  ]);

  const toggleIcon: ReactNode = useMemo(() => {
    if (shouldContentDisabled) {
      return <DisabledToggleSvg />;
    }
    return (
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="top"
        arrow={false}
        content={l10n(isEditing ? Text.tooltipCollapseText : Text.tooltipExpandText)}
      >
        <ToggleIconContainer className={CANCELLABLE_CLASS_NAME} isEditing={isEditing}>
          <ToggleSvg />
        </ToggleIconContainer>
      </Tippy>
    );
  }, [shouldContentDisabled, isEditing]);

  const title: ReactNode = (
    <TitleWrapper className={CANCELLABLE_CLASS_NAME}>
      <Title fromUI={T.EditableTextUI.CONTENT_TITLE} content={content} isEditing={isEditing} />
    </TitleWrapper>
  );

  // This logic is needed because this component is trying to do too much.
  // It wants to have different sizes for different types of content.
  // The design needs to somehow be more consistent than this,
  // or create separate components for each type instead.
  const balloonToolsWidth: string = useMemo(() => {
    if (hasNoDownloadAndDelete) {
      return '13px';
    }

    if (isOverlayContent) {
      return '66px';
    }

    return ESSContent ? '51px' : '39px';
  }, [hasNoDownloadAndDelete, hasDownload, isOverlayContent, ESSContent]);

  const deleteContent: UseDeleteContent = useDeleteContent();

  const handleDeleteContent = () => {
    if (printingContentId || isInDashboard) {
      return;
    }
    // Only the user who is admin of project can delete content,
    // so instead of dispatching DeleteESSContent directly,
    // show popup to confirm deleting selected content
    if (content.id === editingContentId) {
      deleteContent(content.id, content.type, content.category === T.ContentCategory.ESS);
    }
  };

  const handleKeyDown: (e: KeyboardEvent<HTMLElement>) => void = e => {
    if ((e.target as HTMLElement).tagName === T.TagName.INPUT || !isEditing) {
      return;
    }
    e.preventDefault();

    if (!isEditing) {
      return;
    }
    if (e.ctrlKey && e.key === T.ShortCut.D) {
      copySelectedESSContents();
    } else if (e.key === T.ShortCut.DELETE) {
      handleDeleteContent();
    }
  };

  const snapEnabled = useMemo(() => {
    if (content.category !== T.ContentCategory.OVERLAY) {
      return false;
    }
    return snapEnabledDxfIds?.includes(content.id);
  }, [content, snapEnabledDxfIds]);

  const snapEnabledSvg = useMemo(() => {
    if (!snapEnabled) {
      return null;
    }
    return <SnapEnabledIndicator />;
  }, [snapEnabled]);

  const isMarginRequired =
    (content.type === T.ContentType.VOLUME && dtmId) ||
    (isContentDSMorDTM && isDTMContentProcessingCompleted);

  const balloon: ReactNode =
    isEditing && !shouldContentDisabled && !(isContentDSMorDTM && isIn3D) ? (
      <Balloon>
        <BalloonHeader data-testid="balloon-header" isMarginOnTitle={isMarginOnTitle}>
          <BalloonTitle isDisabled={isProcessing && isOverlayContent}>
            <span>{firstBalloonTitle}</span>
            {firstBalloonDescription}
            {balloonColor}
          </BalloonTitle>
          <BalloonCenterTools isMarginRequired={Boolean(isMarginRequired)}>
            {dsmdtmSelectButton}
            {dsmdtmTopSelectButton}
          </BalloonCenterTools>
          <BalloonTools width={balloonToolsWidth}>
            <ContentInfo content={shouldDTMContentBeShown && dtmContent ? dtmContent : content} />
            {downloadButton}
            {copyButton}
            {deleteButton}
          </BalloonTools>
        </BalloonHeader>
        {children}
      </Balloon>
    ) : undefined;

  const trackLabel: string | undefined = useMemo(() => {
    switch (content.type) {
      case T.ContentType.MAP: {
        return '2d';
      }
      case T.ContentType.THREE_D_MESH: {
        return '3d-mesh';
      }
      case T.ContentType.THREE_D_ORTHO: {
        return '3d-ortho';
      }
      case T.ContentType.POINTCLOUD: {
        return '3d-pointcloud';
      }
      case T.ContentType.GCP_GROUP: {
        return 'gcp';
      }
      case T.ContentType.DSM: {
        return 'dsm';
      }
      case T.ContentType.DTM: {
        return 'dtm';
      }
      default: {
        // The rest of the tracking might be more relevant
        // if it has the distinction between being triggered in 2d or 3d,
        // since it behaves pretty differently in these two views.
        return `${content.type}-${isIn3D ? '3d' : '2d'}`;
      }
    }
  }, [content.type, isIn3D]);

  // Only tracks content list click to view
  // when it was not selected previously.
  const trackActionContentsList: string | undefined = useMemo(
    () => (!content?.config?.selectedAt && !isEditing ? 'map-view' : undefined),
    [Boolean(content?.config?.selectedAt), isEditing]
  );

  // Should differ between toggling on and off the content.
  const trackActionContentsListCheckbox: string | undefined = useMemo(
    () => (!content?.config?.selectedAt ? 'map-view' : 'map-view-off'),
    [Boolean(content?.config?.selectedAt)]
  );

  // The only exception for color is ESS text where
  // the color that the icon represents is the font color.
  // It's the requirement.
  const iconColor: string | undefined =
    content.type === T.ContentType.DESIGN_DXF
      ? undefined
      : content.type === T.ContentType.ESS_TEXT || content.type === T.ContentType.ESS_LINE_TEXT
      ? content.info.fontColor.toString()
      : color;
  const borderColor: string | undefined =
    content.type === T.ContentType.ESS_LINE_TEXT ? content.info.borderColor.toString() : color;

  return (
    <Root
      id={`contentid-${contentId}`}
      ref={rootRef}
      color={color}
      className={`${className || ''} ${T.ContentIdentifier.CLASSNAME}`}
      onClick={onClick}
      data-content={`content-${content.id}`}
      data-state={isEditing ? 'show' : 'hide'}
      data-selected={content.config?.selectedAt ? 'true' : 'false'}
      data-pinned={isPinned ? 'false' : 'true'}
      data-testid="content-selection-box"
    >
      <SelectionBox
        groupType={group ? (group.groupId ? 'sub' : 'root') : undefined}
        isParentGroupSelected={isParentGroupSelected}
        isEditing={isEditing}
        onClick={handleToggle}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        data-ctxsort={CONTENTSLIST_CTXSORT_KEY}
        data-ctxsort-key={content.id}
        // onMouseOver={handleMouseOver}
        // onMouseLeave={handleMouseLeave}
        data-ddm-track-action={trackActionContentsList}
        data-ddm-track-label={`${T.TrackLabels.BTN_CONTENTS_LIST}-${trackLabel}`}
      >
        {displayCheckBox && (
          <Checkbox
            content={content}
            isProcessingOrFailed={isProcessingOrFailed}
            isFailed={isFailed}
            trackAction={trackActionContentsListCheckbox}
            trackLabel={`btn-contents-list-checkbox-${trackLabel}`}
          />
        )}

        <ContentIcon
          contentType={content.type}
          color={iconColor}
          isProcessingOrFailed={isProcessingOrFailed}
          isPinned={isPinned}
          isDTMMode={isDTMMode}
          borderColor={borderColor}
        />

        {snapEnabledSvg}

        {title}
        <FeatureWrapper>
          {dsmDtmBadge}
          {dimensionBadge}
          {contentCenterButton}
          {toggleIcon}
        </FeatureWrapper>
      </SelectionBox>
      {balloon}
    </Root>
  );
};

export const ContentsListItem = memo<FC<PropsWithChildren<Props>>>(ContentsListItemRaw);
