import { Language } from '^/types';

export default {
  snappingOn: {
    [Language.KO_KR]: '스냅 활성화',
    [Language.EN_US]: 'Snapping on',
  },
  snap: {
    [Language.KO_KR]: '스냅',
    [Language.EN_US]: 'Snap',
  },
};
