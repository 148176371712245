import React from 'react';
import theme from '^/theme';
import styled from 'styled-components';
import { useL10n } from '^/hooks';
import Text from './text';
const BadgeContainer = styled.div({
  padding: '2px 4px',
  borderRadius: '4px',
  backgroundColor: theme.colors.neutralBlue[50],
  marginLeft: '7px',
});

const BadgeText = styled.span({
  fontSize: '12px',
  fontWeight: '600',
  color: theme.colors.neutralBlue[500],
});

export default function SnapEnabledIndicatorBadge() {
  const [l10n] = useL10n();
  return (
    <BadgeContainer>
      <BadgeText>{l10n(Text.snap)}</BadgeText>
    </BadgeContainer>
  );
}
