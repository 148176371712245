import React from 'react';
import Tippy from '@tippyjs/react';
import SnapEnabledSvg from '^/assets/icons/contents-list/snap-enabled.svg';
import { useL10n } from '^/hooks';

import * as T from '^/types';
import styled from 'styled-components';
import Text from './text';

const SnapSvgWrapper = styled.div({
  marginLeft: '7px',
  display: 'inline-flex',
});

export default function SnapEnabledIndicator() {
  const [l10n] = useL10n();
  return (
    <Tippy
      offset={T.TIPPY_OFFSET}
      theme="angelsw"
      placement="top"
      arrow={false}
      content={l10n(Text.snappingOn)}
    >
      <SnapSvgWrapper>
        <SnapEnabledSvg />
      </SnapSvgWrapper>
    </Tippy>
  );
}
