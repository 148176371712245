import { Tuple } from '^/components/molecules/MyPage/Tuple';
import { useL10n } from '^/hooks';
import { usePopupStore } from '^/store/popup';
import * as T from '^/types';
import React from 'react';
import styled from 'styled-components';
import { GroupedSection } from './GroupedSection';
import Text from './text';

const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingInline: '60px',
  paddingTop: '22px',
});

// kept just in case required in future
// const Badge = styled.div({
//   padding: '4px 8px',
//   border: `1px solid ${theme.colors.neutralGray[200]}`,
//   borderRadius: '8px',
//   color: theme.colors.neutralGray[500],
//   fontSize: '20px',
//   fontWeight: '700',
//   width: 'fit-content',
// });

export default function MyPageBilling() {
  const [l10n] = useL10n();
  const setPopup = usePopupStore(s => s.setMinorPopup);
  const setPopupType = usePopupStore(s => s.setPopupOptions);
  const handleOpenPopup = () => {
    setPopup(T.ContentPageMinorPopupType.MY_PAGE_POPUP);
    setPopupType({
      myPagePopupType: T.MyPagePopupContentTypes.CHANGE_PLAN,
    });
  };

  return (
    <Root data-testid="my-page-billing">
      <GroupedSection
        title={l10n(Text.plan.subscription.title)}
        testId="my-page-billing-subscription"
      >
        <Tuple
          title={l10n(Text.plan.subscription.changePlan)}
          onClick={handleOpenPopup}
          testId="my-page-billing-subscription-change-plan"
        />
      </GroupedSection>
    </Root>
  );
}
