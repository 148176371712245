/* eslint-disable max-lines */
import * as Sentry from '@sentry/browser';
import Tippy from '@tippyjs/react';
import axios from 'axios';
import React, { FC, KeyboardEvent, MouseEvent, ReactNode, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { CSSObject } from 'styled-components';

import CheckSvg from '^/assets/icons/contents-list/check.svg';
import ChevronSvg from '^/assets/icons/contents-list/chevron.svg';
import ClosedGroupSvg from '^/assets/icons/contents-list/group-closed.svg';
import EmptyGroupSvg from '^/assets/icons/contents-list/group-empty.svg';
import GroupLockedSvg from '^/assets/icons/contents-list/group-locked.svg';
import OpenGroupSvg from '^/assets/icons/contents-list/group-open.svg';
import PartialCheckSvg from '^/assets/icons/contents-list/partial-check.svg';
import UncheckSvg from '^/assets/icons/contents-list/uncheck.svg';
import PinSvg from '^/assets/icons/pin.svg';
import UnpinSvg from '^/assets/icons/unpin.svg';
import { GroupedContentsTitle } from '^/components/atoms/GroupedContentsTitle';
import palette from '^/constants/palette';
import {
  UseL10n,
  typeGuardGroupable,
  useAuthHeader,
  useIsRoleX,
  useL10n,
  useLastSelectedScreen,
} from '^/hooks';
import { useESSContents } from '^/hooks/useESSContents';
import { AuthHeader } from '^/store/duck/API';
import { PatchContent } from '^/store/duck/Contents';
import { ChangeSelectedGroupId, CopySelectedGroup } from '^/store/duck/Groups';
import { OpenContentPagePopup } from '^/store/duck/Pages';
import { useESSContentsStore } from '^/store/essContentsStore';
import { useContentsStore } from '^/store/zustand/content/contentStore';
import { useGroupStore } from '^/store/zustand/groups/groupStore';
import * as T from '^/types';
import { isContentPersonal, isContentPinned, isPinnable } from '^/utilities/content-util';
import { isErrorIgnorable } from '^/utilities/http-response';
import { isRoleViewer } from '^/utilities/role-permission-check';
import { getAllChildren } from '^/utilities/state-util';
import { updateContentSelectedAtInServer } from '^/utilities/updateContentSelectedAtInServer';
import { CANCELLABLE_CLASS_NAME } from '../CreatingVolumeClickEventHandler';
import Text from './text';
import { useSnappingStore } from '^/store/snapping/snappingStore';

const RelativeDiv = styled.div({ position: 'relative' });

const GroupTitleWrapper = styled.div({
  flex: 1,
  position: 'relative',
  height: '32px',
  marginBottom: '2px',
  marginLeft: '4px',
});

const GroupIconRoot = styled.div<{ shouldShowLockIcon: boolean }>(({ shouldShowLockIcon }) => ({
  width: '18px',
  marginRight: shouldShowLockIcon ? '3.5px' : '1.5px',
  marginTop: shouldShowLockIcon ? '4px' : '2px',
}));
GroupIconRoot.displayName = 'GroupIconRoot';

const GroupArrowRoot = styled.div<{ isOpened: boolean }>(({ isOpened }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: '20px',
  height: '20px',
  borderRadius: '3px',
  transform: isOpened ? 'rotate(180deg)' : 'none',

  ':hover': {
    backgroundColor: '#CED7E5',
  },
}));
GroupArrowRoot.displayName = 'GroupArrowRoot';

const PinIconWrapper = styled.div(() => ({
  color: '#b1b1b1',
  marginRight: 4,
  ':hover': {
    backgroundColor: '#CED7E5',
    color: '#4d4d4d',
  },
  ...iconStyles,
}));

const iconStyles: CSSObject = {
  height: 20,
  width: 20,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '3px',
  cursor: 'pointer',
};
const GroupCheckboxRoot = styled.div({
  marginRight: '9px',
  ':hover': {
    backgroundColor: '#CED7E5',
  },
  ...iconStyles,
});

GroupCheckboxRoot.displayName = 'GroupCheckboxRoot';

const GroupedContentsHeaderRoot = styled.div<{
  isSelected: boolean;
  isParentGroupSelected: boolean;
  isRootFolder: boolean;
  sidebarTab: T.ContentPageTabType;
}>(({ isSelected, isRootFolder, isParentGroupSelected, sidebarTab }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',

  boxSizing: 'border-box',
  width: '100%',
  height: '40px',
  paddingLeft: isRootFolder ? (sidebarTab === T.ContentPageTabType.MAP ? '24px' : '20px') : '44px',
  paddingRight: '12px',
  fontSize: '15px',
  color: palette.ContentsList.groupHeaderTextGray.toString(),
  backgroundColor: isSelected
    ? palette.GroupedContentsHeader.selected.toString()
    : isParentGroupSelected
    ? palette.ContentsList.childOfSelectedGroup.toString()
    : 'none',
  cursor: 'pointer',

  [`&:hover ${PinIconWrapper}`]: {
    color: '#4d4d4d',
  },
}));
GroupedContentsHeaderRoot.displayName = 'GroupedContentsHeaderRoot';

const GroupArrow: FC<{ isOpened: boolean; onClick(e: MouseEvent<HTMLDivElement>): void }> = ({
  isOpened,
  onClick,
}) => {
  const [l10n]: UseL10n = useL10n();

  return (
    <Tippy
      offset={T.TIPPY_OFFSET}
      theme="angelsw"
      placement="top"
      arrow={false}
      content={l10n(isOpened ? Text.tooltipCollapseText : Text.tooltipExpandText)}
    >
      <GroupArrowRoot isOpened={isOpened} onClick={onClick}>
        <ChevronSvg />
      </GroupArrowRoot>
    </Tippy>
  );
};

const GroupCheckbox: FC<{
  readonly isChecked: boolean;
  readonly isPartiallyChecked: boolean;
  onClick(e: MouseEvent<HTMLDivElement>): void;
}> = ({ isChecked, isPartiallyChecked, onClick }) => {
  const [l10n]: UseL10n = useL10n();

  const checkboxSvg: ReactNode = useMemo(() => {
    if (isPartiallyChecked) {
      return <PartialCheckSvg />;
    }

    if (isChecked) {
      return <CheckSvg />;
    }

    return <UncheckSvg />;
  }, [isChecked, isPartiallyChecked]);

  const checkboxTooltip: string = l10n(
    isChecked ? Text.tooltipUnselectAllText : Text.tooltipSelectAllText
  );

  return (
    <RelativeDiv>
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="top"
        arrow={false}
        content={checkboxTooltip}
      >
        <GroupCheckboxRoot data-testid="group-checkbox" data-selected={isChecked} onClick={onClick}>
          {checkboxSvg}
        </GroupCheckboxRoot>
      </Tippy>
    </RelativeDiv>
  );
};

interface GroupedContentsHeaderProps {
  readonly groupId: T.GroupContent['id'];
  readonly childrenIds: Array<T.Content['id']>;
  readonly isESS?: boolean;
}

/*
 * Group Header
 */
export const GroupedContentsHeader: FC<GroupedContentsHeaderProps> = memo(
  ({ groupId, childrenIds, isESS }) => {
    const { updateESSContent, duplicateESSGroupContent } = useESSContents();
    const {
      essContents,
      essContentGroupTree,
      selectedESSGroupIdByTab,
      setDeletingESSContentId,
      updateSelectedAtInESSContents,
      setSelectedESSGroupIdByTab,
    } = useESSContentsStore(s => ({
      essContents: s.essContents,
      essContentGroupTree: s.essContentGroupTree,
      selectedESSGroupIdByTab: s.selectedESSGroupIdByTab,
      setDeletingESSContentId: s.setDeletingESSContentId,
      updateSelectedAtInESSContents: s.updateSelectedAtInESSContents,
      setSelectedESSGroupIdByTab: s.setSelectedESSGroupIdByTab,
    }));
    const dispatch = useDispatch();
    const currentScreen = useLastSelectedScreen();
    const authHeader: AuthHeader | undefined = useAuthHeader();
    const sidebarTab = useSelector((s: T.State) => s.Pages.Contents.sidebarTab);
    const printingContentId = useSelector((s: T.State) => s.Pages.Contents.printingContentId);
    const projectId = useSelector((s: T.State) => s.Pages.Contents.projectId);
    const byId = useContentsStore(s => s.contents.byId);
    const updateContentsSelectedAtInStore = useContentsStore(
      s => s.updateContentsSelectedAtInStore
    );

    const snapEnabledDxfIds = useSnappingStore(s => s.snapEnabledDxfIds);

    const idsByGroup = useGroupStore(s => s.tree.idsByGroup);
    const selectedMainGroupIdByTab = useGroupStore(s => s.selectedGroupIdByTab);

    const selectedGroupIdByTab = isESS ? selectedESSGroupIdByTab : selectedMainGroupIdByTab;
    // const sidebarTab = state.Pages.Contents.sidebarTab;
    const selectedGroupId = isESS
      ? selectedESSGroupIdByTab[T.ContentPageTabType.ESS]
      : selectedMainGroupIdByTab[sidebarTab];

    const group = isESS
      ? typeGuardGroupable(essContents[groupId])
      : typeGuardGroupable(byId[groupId]);
    const [l10n] = useL10n();
    const isViewer: boolean = useIsRoleX(isRoleViewer);

    const isParentGroupSelected: boolean =
      Boolean(selectedGroupIdByTab[sidebarTab]) &&
      group?.groupId === selectedGroupIdByTab[sidebarTab];
    const isPinned = group ? isContentPinned(group) : false;
    const isPinnableGroup = group ? isPinnable(group.category) : false;
    const isPersonal = group ? isContentPersonal(group) : false;

    const copySelectedGroup: () => void = useCallback(() => {
      if (printingContentId) {
        return;
      }
      if (isViewer) {
        dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));

        return;
      }

      // Avoid lagging behind the previously selected group by clearing them first.
      dispatch(ChangeSelectedGroupId({ tab: sidebarTab }));
      if (isESS) {
        void duplicateESSGroupContent({ isPersonal, selectedGroupId });
      } else {
        dispatch(CopySelectedGroup({ isPersonal, selectedGroupId, isPinned }));
      }
    }, [printingContentId, isViewer, isPersonal, isPinned, selectedGroupId, essContentGroupTree]);

    const deleteGroupConfirm: () => void = useCallback(() => {
      if (printingContentId) {
        return;
      }
      if (isViewer) {
        dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.NO_PERMISSION }));

        return;
      }
      if (isESS) {
        setDeletingESSContentId(groupId);
      }
      dispatch(OpenContentPagePopup({ popup: T.ContentPagePopupType.DELETE_GROUP }));
    }, [printingContentId]);

    const isSelected: boolean = group?.id === selectedGroupIdByTab[sidebarTab];

    if (
      group === undefined ||
      // sidebarTab === T.ContentPageTabType.MAP ||
      sidebarTab === T.ContentPageTabType.PHOTO
    ) {
      return null;
    }

    const children = getAllChildren(
      childrenIds,
      isESS ? essContentGroupTree : idsByGroup,
      isESS ? essContents : byId
    );

    const hasSnapEnabledChild = useMemo(() => {
      if (group.category !== T.ContentCategory.OVERLAY) {
        return false;
      }
      return childrenIds.some(childId => snapEnabledDxfIds.includes(childId));
    }, [childrenIds, snapEnabledDxfIds]);

    const selectedChildren = children.filter((child: T.Content) => child.config?.selectedAt);
    const noChildrenSelected = selectedChildren.length === 0;
    const hasAllChildrenSelected =
      !noChildrenSelected && selectedChildren.length === children.length;
    const isChecked =
      (children.length === 0 && Boolean(group?.config?.selectedAt)) || hasAllChildrenSelected;
    const hasGroupChild = Boolean(children.find(child => child.type === T.ContentType.GROUP));
    const isPartiallyChecked = !noChildrenSelected && selectedChildren.length < children.length;

    const handleGroupArrowClick: (e: MouseEvent<HTMLDivElement>) => void = e => {
      e.stopPropagation();
      switch (group.category) {
        case T.ContentCategory.ESS: {
          void updateESSContent({
            content: { id: group.id, info: { isOpened: !group.info?.isOpened } },
          });
          break;
        }
        default:
          dispatch(
            PatchContent({ content: { id: group.id, info: { isOpened: !group.info?.isOpened } } })
          );
      }
    };

    const handleGroupHeaderClick: () => void = () => {
      if (isESS) {
        setSelectedESSGroupIdByTab(
          group.id === selectedESSGroupIdByTab[sidebarTab] ? undefined : group.id
        );
      } else {
        dispatch(
          ChangeSelectedGroupId({
            selectedGroupId: group.id === selectedGroupIdByTab[sidebarTab] ? undefined : group.id,
            tab: sidebarTab,
          })
        );
      }
    };

    function alreadyUpToDateContents(content: T.Content): boolean {
      return isChecked ? true : !content?.config?.selectedAt;
    }

    const handleToggleGroupCheckbox: (e: MouseEvent<HTMLDivElement>) => void = async e => {
      try {
        e.stopPropagation();
        if (isESS && groupId) {
          const essGroup = essContents[groupId];
          if (essGroup.groupId) {
            setSelectedESSGroupIdByTab(essGroup.groupId);
            void updateESSContent({
              content: { id: essGroup.groupId, info: { isOpened: true } },
            });
          }
        } else {
          dispatch(ChangeSelectedGroupId({ selectedGroupId: groupId, tab: sidebarTab }));
        }

        const selectedAt: Date | undefined = isChecked ? undefined : new Date();

        // Note: Technically an empty group doesn't need to have
        // the ability to be toggled, but it's done anyway to make it consistent
        // with the previous behavior.
        switch (group.category) {
          case T.ContentCategory.ESS: {
            void updateESSContent({
              content: { id: groupId, config: { selectedAt } },
              skipDBUpdate: true,
            });
            break;
          }
          default:
            dispatch(PatchContent({ content: { id: groupId, config: { selectedAt } } }));
        }
        const childrenContents = getAllChildren(
          childrenIds,
          isESS ? essContentGroupTree : idsByGroup,
          isESS ? essContents : byId
        );
        if (childrenContents.length === 0) {
          return;
        }
        const outdatedContents: T.Content[] = childrenContents.filter(alreadyUpToDateContents);
        const requests: Promise<void[]> = updateContentSelectedAtInServer(
          outdatedContents,
          authHeader,
          projectId,
          selectedAt
        );

        if (isESS) {
          const ids = outdatedContents.map(c => c.id);
          updateSelectedAtInESSContents(ids, selectedAt);
        } else {
          const outdatedContentsIds = outdatedContents.map(c => c.id);
          updateContentsSelectedAtInStore(outdatedContentsIds, selectedAt);
        }
        await requests;
      } catch (event) {
        // eslint-disable-next-line no-console
        console.error(event);
        if (!axios.isAxiosError(event) || !isErrorIgnorable(event.response?.status)) {
          Sentry.captureException(event);
        }
      }
    };

    const handleKeyDown: (e: KeyboardEvent<HTMLElement>) => void = e => {
      if ((e.target as HTMLElement).tagName === T.TagName.INPUT) {
        return;
      }
      if (!isSelected || sidebarTab !== T.ContentPageTabType.ESS) {
        return;
      }
      e.preventDefault();

      if (e.ctrlKey && e.key === T.ShortCut.D) {
        copySelectedGroup();
      } else if (e.key === T.ShortCut.DELETE) {
        deleteGroupConfirm();
      }
    };

    // const handleMouseEnter = () => {
    //   setIshovering(true);
    // };

    // const handleMouseLeave = () => {
    //   setIshovering(false);
    // };

    const handleTogglePin = (e: MouseEvent) => {
      e.stopPropagation();
      // screenId is undefined if it is not present in actin paylaod
      // we need to explictly defined that screenId is null
      const newScreenId: number | undefined = group.screenId ? (null as any) : currentScreen?.id;
      dispatch(
        PatchContent({
          content: { id: group.id, screenId: newScreenId },
        })
      );
    };

    const togglePinIcon = (
      <Tippy
        offset={T.TIPPY_OFFSET}
        theme="angelsw"
        placement="top"
        arrow={false}
        content={l10n(group.screenId ? Text.tooltipPinText : Text.tooltipUnpinText)}
      >
        <PinIconWrapper
          data-state={group.screenId ? 'pinned' : 'unpinned'}
          onClick={handleTogglePin}
        >
          {!group.screenId ? <PinSvg /> : <UnpinSvg />}
        </PinIconWrapper>
      </Tippy>
    );

    const GroupIcon: FC = () => {
      const shouldShowLockIcon = isPersonal;
      const icon = (() => {
        if (childrenIds.length === 0) {
          return <EmptyGroupSvg />;
        }
        if (shouldShowLockIcon) {
          return <GroupLockedSvg />;
        }
        if (Boolean(group.info.isOpened)) {
          return <OpenGroupSvg />;
        }
        return <ClosedGroupSvg />;
      })();

      return (
        <GroupIconRoot
          data-testid="group-icon"
          data-state={
            Boolean(group.info.isOpened) ? 'opened' : childrenIds.length === 0 ? 'empty' : 'closed'
          }
          shouldShowLockIcon={shouldShowLockIcon}
        >
          {icon}
        </GroupIconRoot>
      );
    };

    // sub-folder => data-selected
    // group => data-selected
    //sub-folder => fold/unfold
    return (
      <GroupedContentsHeaderRoot
        data-ctxsort="Group"
        data-ctxsort-is-root-group={group.groupId ? '' : 'true'}
        data-ctxsort-has-subgroup={hasGroupChild ? 'true' : 'false'}
        data-ctxsort-key={group.id}
        sidebarTab={sidebarTab}
        onClick={handleGroupHeaderClick}
        // onMouseEnter={handleMouseEnter}
        // onMouseLeave={handleMouseLeave}
        onKeyDown={handleKeyDown}
        isSelected={isSelected}
        isParentGroupSelected={isParentGroupSelected}
        isRootFolder={!Boolean(group.groupId)}
        data-content={`group-${group.id}`} // Do not touch this ⚠️ , utilized for context menu
        data-testid={`${isPersonal ? 'personal-space-' : 'open-space-'}${
          group.groupId ? 'sub-group' : 'group'
        }-${group.id}`}
        className={T.ContentIdentifier.CLASSNAME}
        tabIndex={0}
        data-state={group.info?.isOpened ? 'show' : 'hide'}
        data-selected={group.config?.selectedAt ? 'true' : 'false'}
        data-pinned={group.screenId ? 'false' : 'true'}
      >
        <GroupCheckbox
          isChecked={isChecked}
          isPartiallyChecked={isPartiallyChecked}
          onClick={handleToggleGroupCheckbox}
        />
        <GroupIcon />
        <GroupTitleWrapper className={CANCELLABLE_CLASS_NAME}>
          <GroupedContentsTitle
            fromUI={T.EditableTextUI.GROUP_TITLE}
            group={group}
            initialText={group.title}
            isEditing={true}
            isSnapEnabled={hasSnapEnabledChild}
          />
        </GroupTitleWrapper>
        {isPinnableGroup && !Boolean(group.groupId) && togglePinIcon}
        <GroupArrow isOpened={group.info?.isOpened ?? false} onClick={handleGroupArrowClick} />
      </GroupedContentsHeaderRoot>
    );
  }
);
