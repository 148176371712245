import { Tuple } from '^/components/molecules/MyPage/Tuple';
import { formatMonthDayYear } from '^/components/molecules/PhotoList/util';
import { useL10n } from '^/hooks';
import { usePopupStore } from '^/store/popup';
import { useAuthUserQuery } from '^/store/react-query/users';
import theme from '^/theme';
import * as T from '^/types';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { GroupedSection } from './GroupedSection';
import Text from './text';
const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  paddingInline: '60px',
  paddingTop: '22px',
});

export default function MyPageSecurity() {
  const [l10n, lang] = useL10n();
  const setMinorPopup = usePopupStore(s => s.setMinorPopup);
  const setPopupOptions = usePopupStore(s => s.setPopupOptions);

  const { data: authedUser } = useAuthUserQuery();

  const formattedDate = useMemo(() => {
    if (!authedUser?.passwordUpdatedAt) {
      return undefined;
    }
    const date = new Date(authedUser?.passwordUpdatedAt);
    const dayMonthYear = formatMonthDayYear(date, lang);
    return dayMonthYear;
  }, [authedUser?.passwordUpdatedAt]);

  const handleOpenPopup = (type: T.MyPagePopupContentTypes) => {
    setPopupOptions({ myPagePopupType: type });
    setMinorPopup(T.ContentPageMinorPopupType.MY_PAGE_POPUP);
  };

  return (
    <Root data-testid="my-page-security">
      <GroupedSection title={l10n(Text.security.title)} testId="my-page-security-security">
        <Tuple
          title={l10n(Text.security.password)}
          value={formattedDate ? `${l10n(Text.security.lastChanged)} ${formattedDate}` : undefined}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.CHANGE_PASSWORD)}
          testId="my-page-security-password"
        />
        <Tuple
          title={l10n(Text.security.deleteAccount)}
          titleCustomStyles={{
            fontWeight: '700',
            color: theme.colorTokens.errorRed,
          }}
          onClick={() => handleOpenPopup(T.MyPagePopupContentTypes.DELETE_ACCOUNT)}
          testId="my-page-security-delete-account"
        />
      </GroupedSection>
    </Root>
  );
}
